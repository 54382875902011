



































import {Component, Prop, Provide, Vue} from 'vue-property-decorator'
import {SupportedToken} from '@/model/resource/SupportedToken'
import {InputSupportedTokenSchema} from '@/schema/resource/SupportedToken/InputSupportedTokenSchema'

@Component
export default class PersistSupportedTokenView extends Vue {
  @Prop() id?: string

  @Provide('validator') validator = this.$validator

  schema = new InputSupportedTokenSchema()
  supportedToken = new SupportedToken()

  async created() {
    await this.populate()
  }

  async populate() {
    const id = Number(this.id) || null

    if (id) {
      await this.supportedToken.getSupportedToken(id)
    }

    this.$await.done('getSupportedToken')
  }

  async persist() {
    const isValid = await this.validator.validateAll()

    if (!isValid) {
      this.$toast.abort('system.error.validation')
    }

    await this.supportedToken.persistSupportedToken()
    this.$toast.success('system.success.persist')
    await this.$nav.push('/supportedToken/list')
  }
}
