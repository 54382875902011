/**
 * Input Schema of SupportedToken
 * @author Lopes
 */
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet, FieldComponent} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-input'
import {Token} from '@/model/resource/Token'
import FileManagerWithList from '@/components/FileManagerWithList.vue'
import {$} from '@/facade'

/* TODO: review generated schema */
export class InputSupportedTokenSchema extends DefaultSchema {
  readonly name = 'InputSupportedToken'

  readonly fieldSet: FieldSet<Token> = {
    title: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'text',
        maxlength: 70,
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
      },
    }),
    order: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'number',
        maxlength: 255,
        step: 1,
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
      },
    }),
    imageUrl: (schema): FieldComponent => ({
      is: FileManagerWithList,
      bind: {
        folder: 'supportedToken',
        compressDimension: $.auth.global.dappImageDimension,
        crop: false,
        class: 'scoped__image-url',
      },
    }),
  }
}
